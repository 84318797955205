<template>
    <section class="section">
        <div class="container">
            <div class="event">
                <Placeholder v-if="isLoading" />
                <div v-else>
                    <div class="card">
                        <div class="card__body">
                            <div class="event">
                                <div class="row">
                                    <div class="col-md-5">
                                        <VLazyImage
                                            :src="event.image"
                                            :src-placeholder="placeholder"
                                            class="event-image"
                                        />
                                    </div>
                                    <div class="col-md-7">
                                        <div class="event-info">
                                            <h4>{{ event.title }}</h4>
                                            <small>Date</small>
                                            <p>{{ event.expiry }}</p>
                                            <small>Description</small>
                                            <p class="description">{{ event.description }}</p>
                                            <small>Organizer</small>
                                            <p>{{ event.organizer.name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4" v-for="(category, index) in categories" :key="index" >
                            <div class="category" @click="onCategorySelect(category)">{{ category.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
} from "@firebase/firestore";
import dayjs from "dayjs";
const db = getFirestore();
export default {
    data() {
        return {
            isLoading: false,
            isLoadingContestants: false,
            isDonating: false,
            event: {},
            categories: [],
            nominees: [],
            isFixed: false,
            placeholder: "/img/placeholder.jpg",
            loadContestantsByCategory: [],

            checkout: {
                value: null,
                amount: 0,
                email: "",
                name: "",
                telephone: "",
                isAnonymous: false,
                type: "CONTEST",
                votes: "",
            },
        };
    },
    created() {
        this.getEvent();
        this.getCategories();
    },
    methods: {

        async getEvent() {
            this.isLoading = true;
            const eventId = this.$route.params.eventId;
            const ref = doc(db, `events/${eventId}`);
            const snapshot = await getDoc(ref);
            const data = snapshot.data();
            data.expiry = dayjs(data.date.toDate()).format("DD MMMM, YYYY");
            data.company = data.organizer.name;
            this.event = data;
            this.isLoading = false;
        },

        async getCategories() {
            const eventId = this.$route.params.eventId;
            const catRef = collection(db, "events", eventId, "categories");
            const catSnapshot = await getDocs(catRef);
            const categories = catSnapshot.docs.map((doc) => {
                const data = doc.data();
                data.category_id = doc.id;
                return data;
            });
            this.categories = categories;
        },

        async getContestants() {
            this.isLoadingContestants = true;

            const data = [];

            const eventId = this.$route.params.eventId;
            const catRef = collection(db, "events", eventId, "categories");
            const catSnapshot = await getDocs(catRef);
            const categories = catSnapshot.docs.map((doc) => {
                const data = doc.data();
                data.category_id = doc.id;
                return data;
            });

            const nomRef = collection(db, "events", eventId, "nominees");
            const nomSnapshot = await getDocs(nomRef);
            const nominees = nomSnapshot.docs.map((doc) => {
                const data = doc.data();
                data.nomineeId = doc.id;
                return data;
            });

            for (const category of categories) {
                const { category_id, name } = category;
                const nominees_by_category = nominees.filter((nominee) => {
                    return nominee.categoryId == category_id;
                });
                data.push({
                    category_id,
                    category_name: name.toUpperCase(),
                    category_contestants: nominees_by_category,
                });
            }

            this.loadContestantsByCategory = data;

            this.isLoadingContestants = false;
        },

        onVote(nomineeId) {
            const eventId = this.$route.params.eventId;
            this.$router.push("/events/c/" + eventId + "/n/" + nomineeId);
        },

        onCategorySelect(category) {
            const eventId = this.$route.params.eventId;
            const { category_id, name } = category;
            this.$router.push("/events/c/" + eventId + "/c/" + category_id + "?category=" + name ).catch(() => {});
        },

    },
};
</script>
